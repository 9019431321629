



























  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    name: 'TransactionCancel',
    components: {},
    mixins: [],
  })
  export default class TransactionCancel extends Vue {
    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
